<template lang='pug'>
	.profile
		div(v-show='me && !isPending')
			h2.mb-4(v-if="me.hasFilledAccount()") {{ $t('project.profile') }}

			form-profile(v-if="!me.hasFilledAccount()")
			table-profile(v-else @onCreated='beforeShowTable' @onLoad='beforeShowTable')
		.loading(v-show='!me || isPending')
</template>

<script>
import FormProfile from "@/components/Form/FormProfile";
import TableProfile from "@/components/Tables/TableProfile";

export default {
	name: 'Profile',
	components: {
		FormProfile,
		TableProfile
	},
	metaInfo () { return {title: this.$t('project.profile')}},
	data: () => ({
		isPending: false,
	}),
	computed: {

	},
	methods: {
		beforeShowTable( v ) {
			this.isPending = v;
		}
	}
}
</script>
